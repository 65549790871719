import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '@shared';
import { AddEditRDSComponent } from './add-edit-rds/add-edit-rds.component'; // Adjust the path as necessary
import { ManageClusterUsersComponent } from './manage-cluster-users/manage-cluster-users.component';
import { ManageRDSComponent } from './manage-rds/manage-rds.component';
import { RDSRoutingModule } from './rds-routing.module';
import { CreateProxyEndpointComponent } from './create-proxy-endpoint/create-proxy-endpoint.component';
@NgModule({
  declarations: [
    CreateProxyEndpointComponent,
    AddEditRDSComponent,
    ManageRDSComponent,
    ManageClusterUsersComponent,
  ],
  imports: [SharedModule, FormsModule, RDSRoutingModule],
})
export class RDSModule {}
