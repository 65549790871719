import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HeaderParams, MprHttpHeaderModal } from '@core/interfaces';
import { CommonResponseModel } from '@shared/interfaces';
import { RDSClusterUsersResponse } from 'app/state';
import {
  GenerateTokenResponseModel,
  RDSResponse,
  SetupOrEditClusterPayload,
} from 'app/state/manage-rds/manage-rds.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ManageRDSService {
  private rdsClusterCreateProxyEndpoint = '/rds/proxy';
  private rdsClusterMgmtEndpoint = '/rds';
  private rdsClusterMgmtTokenEndpoint = '/rds/token';
  private rdsClusterUsersEndpoint = '/rds/users';

  constructor(private http: HttpClient) {}

  public addUserToRDSCluster(
    projectId: string,
    rdsResourceId: string,
    userId: string,
    requestHeaders: MprHttpHeaderModal
  ): Observable<CommonResponseModel> {
    const headers = this.getRequestHeaders(requestHeaders);
    return this.http.post<CommonResponseModel>(
      this.rdsClusterUsersEndpoint,
      {
        projectId,
        rdsResourceId,
        userId,
      },
      { headers }
    );
  }

  public createProxyEndpoint(payload: any): Observable<CommonResponseModel> {
    return this.http.post<CommonResponseModel>(
      this.rdsClusterCreateProxyEndpoint,
      payload
    );
  }

  public deleteRDSCluster(
    projectId: string,
    rdsResourceId: string,
    requestHeaders: MprHttpHeaderModal
  ): Observable<CommonResponseModel> {
    const headers = this.getRequestHeaders(requestHeaders);
    const body = {
      projectId,
      rdsResourceId,
    };
    return this.http.delete<CommonResponseModel>(this.rdsClusterMgmtEndpoint, {
      body,
      headers,
    });
  }

  public deleteUserFromRDSCluster(
    projectId: string,
    rdsResourceId: string,
    userId: string,
    requestHeaders: MprHttpHeaderModal
  ): Observable<CommonResponseModel> {
    const headers = this.getRequestHeaders(requestHeaders);
    return this.http.delete<CommonResponseModel>(this.rdsClusterUsersEndpoint, {
      body: {
        projectId,
        rdsResourceId,
        userId,
      },
      headers,
    });
  }

  public generateProxyToken(
    rdsResourceId: string,
    attributeId: string
  ): Observable<GenerateTokenResponseModel> {
    return this.http.get<GenerateTokenResponseModel>(
      `${this.rdsClusterMgmtTokenEndpoint}?rdsResourceId=${rdsResourceId}&attributeId=${attributeId}`
    );
  }

  public generateRDSClusterToken(
    rdsResourceId: string
  ): Observable<GenerateTokenResponseModel> {
    return this.http.get<GenerateTokenResponseModel>(
      `${this.rdsClusterMgmtTokenEndpoint}?rdsResourceId=${rdsResourceId}`
    );
  }

  public getRDSClusterUsers(
    clusterId: string,
    requestHeaders?: MprHttpHeaderModal
  ): Observable<RDSClusterUsersResponse> {
    const rdsUserAPI = `/rds/users?rdsResourceId=${clusterId}`;
    if (requestHeaders) {
      const headers = this.getRequestHeaders(requestHeaders);
      return this.http.get<RDSClusterUsersResponse>(rdsUserAPI, { headers });
    }

    return this.http.get<RDSClusterUsersResponse>(rdsUserAPI);
  }

  public getRDSClusters(
    requestHeaders: MprHttpHeaderModal
  ): Observable<RDSResponse> {
    const headers = this.getRequestHeaders(requestHeaders);
    return this.http.get<RDSResponse>(this.rdsClusterMgmtEndpoint, { headers });
  }

  public setupOrEditRDSCluster(
    payload: SetupOrEditClusterPayload,
    isEdit: boolean,
    requestHeaders: MprHttpHeaderModal
  ): Observable<CommonResponseModel> {
    const headers = this.getRequestHeaders(requestHeaders);

    // Method for post and put
    if (isEdit) {
      return this.http.put<CommonResponseModel>(
        this.rdsClusterMgmtEndpoint,
        payload,
        { headers }
      );
    }

    return this.http.post<CommonResponseModel>(
      this.rdsClusterMgmtEndpoint,
      payload,
      { headers }
    );
  }

  public startOrStopCluster(
    projectId: string,
    rdsResourceId: string,
    action: string,
    requestHeaders: MprHttpHeaderModal
  ): Observable<CommonResponseModel> {
    const headers = this.getRequestHeaders(requestHeaders);
    return this.http.put<CommonResponseModel>(
      this.rdsClusterMgmtEndpoint,
      { projectId, rdsResourceId, action },
      { headers }
    );
  }

  private getRequestHeaders(headers: MprHttpHeaderModal): HttpHeaders {
    const requestHeaders = new HttpHeaders()
      .set(HeaderParams.PROJECTID, headers[HeaderParams.PROJECTID])
      .append(HeaderParams.ROLENAME, headers[HeaderParams.ROLENAME]);
    return requestHeaders;
  }
}
