<ng-container
  *ngIf="{
    userListLoading: (userListInProgress$ | async),
    clusterListLoading: (clusterUserListingInProgress$ | async),
    addedUsers: (usersAddedToCluster$ | async)
  } as templateVars"
>
  <div
    class="mpr-dashboard-loader"
    *ngIf="
      (templateVars.userListLoading ||
        !templateVars.addedUsers ||
        templateVars.clusterListLoading) &&
        !pageLoadError;
      else showProjectUsers
    "
  >
    <div class="mpr-noresult-container">
      <div class="mpr-noresutl-error">
        <span>
          <mat-spinner diameter="45" color="accent"></mat-spinner>
        </span>
      </div>
    </div>
  </div>
  <ng-template #showProjectUsers>
    <div class="field-container">
      <div class="field-label mpr-project-data">
        <span *ngIf="isPlatformAdmin">PROJECT : {{ projectId }} | </span>CLUSTER
        : {{ clusterName }} (<span class="mat-column-status"
          ><span [ngClass]="clusterStatus">{{ clusterStatus }}</span></span
        >)
      </div>
    </div>
    <div class="field-container m-t-16">
      <div class="mpr-table-header-text" *ngIf="!isClusterRunning()">
        <span class="p-4">
          <img
            class="mpr-warning-icon"
            src="assets/images/warning-icon-red-triangle.svg"
            alt="The cluster should be in Running status, before you can make any changes to
            the users."
          />
        </span>
        The cluster should be in Running status, before you can make any changes
        to the users.
      </div>
    </div>
    <div *ngIf="!pageLoadError; else genericerror">
      <mat-table
        [dataSource]="dataSource"
        class="mpr-tables"
        role="table"
        aria-rowcount="-1"
      >
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef scope="col">
            User Name
          </mat-header-cell>
          <mat-cell *matCellDef="let user">
            <span
              class="mpr-ellipsis"
              matTooltip="{{ user.firstName }} {{ user.lastName }}"
            >
              {{ user.firstName }} {{ user.lastName }}
            </span>
            <span
              *ngIf="user.userStatus === 'disabled'"
              class="d-flex m-l-8 mpr-disabled-wrapper"
            >
              <span class="mpr-danger-text"> DISABLED </span>
              <span>
                <button
                  class="mpr-btn-transparency m-t-0"
                  [mtxTooltip]="disableReason"
                  [mprMtxTooltipAriaDescribedBy]="disableReason"
                  #tooltip="mtxTooltip"
                  [attr.aria-label]="user.reason"
                >
                  <mat-icon
                    role="img"
                    class="mat-icon notranslate mpr-info-icon mpr-info-big-icon material-icons mat-icon-no-color mpr-icon-info"
                    aria-hidden="true"
                    >info
                  </mat-icon>
                </button>
                <ng-template #disableReason>
                  <div class="mpr-popover-width" id="mpr-file-note">
                    {{ user.reason }}
                  </div>
                </ng-template>
              </span>
            </span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="role">
          <mat-header-cell *matHeaderCellDef scope="col">
            Role
          </mat-header-cell>
          <mat-cell *matCellDef="let user">
            {{ user.roleName | replaceRoleName }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="dbUserName">
          <mat-header-cell *matHeaderCellDef scope="col">
            DB User Name
          </mat-header-cell>
          <mat-cell *matCellDef="let user">
            <div
              *ngIf="
                getUserIfAlreadyAddedToCluster(
                  user.userId,
                  templateVars.addedUsers
                ) as addUser;
                else na
              "
            >
              <span>{{ addUser.dbUserName }}</span>
              <span class="mpr-private-space-copy">
                <button
                  [mprCopyToClipboard]="addUser.dbUserName"
                  matTooltip="Copy DB User Name"
                  aria-label="Copy DB User Name"
                ></button>
              </span>
            </div>
            <ng-template #na>
              <div>NA</div>
            </ng-template>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="action">
          <mat-header-cell *matHeaderCellDef scope="col"
            >Action</mat-header-cell
          >
          <mat-cell *matCellDef="let user">
            <span
              *ngIf="
                getUserIfAlreadyAddedToCluster(
                  user.userId,
                  templateVars.addedUsers
                )
                  ? 'remove'
                  : 'add' as buttonAction
              "
            >
              <button
                #skipper
                (click)="showAddRemoveClusterUserPopup(user, buttonAction)"
                class="mpr-btn-transparency mpr-button-link mpr-cursor-pointer mpr-underline mpr-skipper mpr-title-case"
                [ngClass]="{
                  'mpr-disabled':
                    !isClusterRunning() ||
                    (user.userStatus === 'disabled' && buttonAction === 'add')
                }"
                [class]="'mpr-action-' + buttonAction"
              >
                {{ buttonAction }}
              </button>
            </span>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let user; columns: displayedColumns"></mat-row>
      </mat-table>
    </div>
  </ng-template>
  <ng-template #genericerror>
    <div class="mpr-table-header-text">
      <span class="p-4">
        <img
          class="mpr-warning-icon"
          src="assets/images/warning-icon-red-triangle.svg"
          alt="An error has occured while loading this page. Please check the url."
        />
      </span>
      An error has occured while loading this page. Please check the url.
    </div>
  </ng-template>
</ng-container>
