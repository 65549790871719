import { ConnectionDataStore, ConnectionType } from './enums';
import { environment } from '@env/environment';

export const INTERNAL_S3 = 'Internal S3';

export const DL_S3_CONNECTION = {
  connectionStatus: 'active',
  projectId: '',
  dataStore: ConnectionDataStore.DL_S3,
  createdDate: '',
  connectionName: INTERNAL_S3,
  connectionType: ConnectionType.INGESTION,
  emailId: '',
  connectionId: ConnectionDataStore.DL_S3,
  modifiedDate: '',
  createdBy: '',
  createdByName: '',
  modifiedBy: '',
};
export const FORSTA_SFTP_CONNECTION = {
  connectionStatus: 'active',
  projectId: '',
  dataStore: ConnectionDataStore.FORSTA,
  createdDate: '',
  connectionName: 'Forsta SFTP',
  connectionType: ConnectionType.INGESTION,
  emailId: '',
  connectionId: ConnectionDataStore.FORSTA,
  modifiedDate: '',
  createdBy: '',
  createdByName: '',
  modifiedBy: '',
};
export const FILES_READY_FOR_SEARCH = 'Uploaded Files';
export const HOME = 'HOME';
export const LOCAL_DRIVE_SOURCE = {
  connectionStatus: 'active',
  projectId: '',
  dataStore: ConnectionDataStore.LOCAL,
  createdDate: '',
  connectionName: 'Local Drive',
  connectionType: ConnectionType.INGESTION,
  emailId: '',
  connectionId: ConnectionDataStore.LOCAL,
  modifiedDate: '',
  createdBy: '',
  createdByName: '',
  modifiedBy: '',
};
export const MANAGE_DATA_CONNECTIONS = 'MANAGE DATA CONNECTIONS';
export const CONNECT_AWS_ACCOUNT = 'CONNECT AWS ACCOUNT';
export const PROJECT_SETTINGS = 'PROJECT SETTINGS';
export const RELATIONAL_DATABASE_SERVICE = 'RELATIONAL DATABASE SERVICE (RDS)';
export const NONE = 'None';
export const QUARANTINED_FILES = 'PII/PHI Data Detected';
export const SEARCH_DATA_CATALOG = 'SEARCH DATA CATALOG';
export const SKIPPED_FILES = 'Unsupported Files';
export const PUBLISH_TO_CATALOG = 'PUBLISH TO CATALOG';
export const WORKSPACE = 'WORKSPACE';
export const DATA_WAREHOUSE = 'DATA WAREHOUSE';
export const FILE_MANAGER = 'FILE MANAGER';
export const UPLOAD_PROJECT_STAGING = 'UPLOAD TO PROJECT STAGING';
export const URLS = 'URL(S)';
export const MANAGE_USERS = 'MANAGE USERS';
export const USAGE_STATEMENT = 'USAGE STATEMENT';
export const VIEW_WORKSPACES = 'VIEW WORKSPACES';
export const MANAGE_PROJECT = 'MANAGE PROJECT';
export const MANAGE_PROJECTS = 'MANAGE PROJECTS';
export const ADMIN_CONSOLE = 'ADMIN CONSOLE';
export const PLATFORM_ADMIN_CONSOLE = 'PLATFORM ADMIN CONSOLE';
export const formatOptions = [
  { value: 'Snowflake', displayName: 'Snowflake' },
  { value: 'Web URL', displayName: 'Web URL' },
  { value: 'Chart', displayName: 'Chart' },
  { value: 'API', displayName: 'API' },
];

export const USER_DATA_FIELDS = {
  firstName: 'First Name',
  lastName: 'Last Name',
  emailId: 'Email',
  roleName: 'Role',
  isPowerUser: 'Is Power User',
  machineSize: 'Workspace Type',
  projectRestrictedDataUseGroups: 'Restricted Data Use Groups',
  userStatus: 'Status',
};

export const EXPORT_CSV_ALL_USER_DATA_FIELDS = {
  userName: 'User Name',
  emailId: 'Email',
  roleName: 'User Role',
  platformLevelUserStatus: 'Platform-level User Status',
  disabledDate: 'User Disabled Date (UTC)',
  disabledReason: 'User Disabled Reason',
  lastLoginTime: 'Last Login (UTC)',
  projectNumber: 'Project Number',
  projectName: 'Project Name',
  projectLevelUserStatus: 'Project-level User Status',
  projectOnboardedDate: 'User Onboarded Date (UTC)',
  projectOffboardedDate: 'User Offboarded Date (UTC)',
  machineSize: 'EC2 Instance',
  amazonWorkspaceAccess: 'Amazon Workspace',
  projectRestrictedDataUseGroups: 'Restricted Data Access Groups',
  stagingAccess: 'Access to File Manager (S3 staging)',
  rdsAccess: 'RDS ',
  snowflakeAccess: 'Snowflake Data Warehouse',
  isPowerUser: 'Power User',
  bedrockAccess: 'Bedrock ',
  pbmqAccess: 'Powered by Mquiry',
};

export const EXPORT_CSV_PLATFORM_ADMIN_USER_DATA_FIELDS = {
  userName: 'User Name',
  emailId: 'Email',
  roleName: 'Role',
  userStatus: 'Status',
  disabledDate: 'User Disabled Date (UTC)',
  disabledReason: 'User Disabled Reason',
  lastLoginTime: 'Last Login Time (UTC)',
};
export const RESTRICTED_GROUP_REGEX = '^[A-Za-z0-9]+[a-zA-Z0-9-_.]*$';

export const EXPORT_CSV_ALL_PROJECT_DATA_FIELDS = {
  projectId: 'Project Id',
  projectName: 'Project Name',
  createdDate: 'Project Created Date (UTC)',
  createdByName: 'Created By',
  modifiedByName: 'Modified By',
  externalProjectAccountsEnabled: `Powered By ${environment.appName} Enabled`,
  rdsEnabled: 'Relational Database Service Enabled',
  serviceAccountEnabled: 'Service Account Enabled',
  dwAccess: 'Data Warehouse Enabled',
  hasPowerUsers: 'Heavy Lifting Enabled',
};

export const EMAIL_REGEX =
  // eslint-disable-next-line @typescript-eslint/quotes
  "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$";

export const workspaceTypes = [
  { category: 'MEMORY OPTIMIZED', value: 'R5.XLARGE' },
  { category: 'COMPUTE OPTIMIZED', value: 'C5.LARGE' },
  { category: 'ACCELERATED COMPUTING', value: 'G4DN.XLARGE' },
];

export const ec2InstanceTypes = [
  { category: 'Memory Optimized', value: 'r5.xlarge' },
  { category: 'Compute Optimized', value: 'c5.large' },
  { category: 'Accelerated Computing', value: 'g4dn.xlarge' },
  { category: '', value: 'Others' },
];

export const R_STUDIO_VERSIONS = ['4.0.2', '4.1.3', '4.2.1', '4.2.3'];

export const HTTP_RESPONSE_STRING_SUCCESS = 'Success';

export const LOCALHOST = 'localhost';

export const USER_IDLE_CONFIG = {
  localStorageKey: 'user-idle',
  timeoutMilliseconds: 1800000,
};

export const USER_STATUS = {
  DISABLED: 'disabled',
  ENABLED: 'enabled',
};

export const USER_STATUS_DISPLAY = {
  DISABLED: 'Disabled',
  ENABLED: 'Enabled',
};

export const RDS_DB_USER_NOT_ADDED = 'Not added';

// Seconds before allowing user enable post a disable.
export const SECONDS_BEFORE_USER_ENABLE = 3600;

// Seconds to 'sleep' before refreshing the page for user-project listing
export const WAIT_SECONDS_BEFORE_REFRESH_ON_ENABLE = 10000;

export const DISABLED_BY_ADMIN = 'Disabled by admin';

export const ENABLE_DISABLED_REASON_TOOLTIP =
  'This account is disabled REASON. Please wait 1 hour before it can be re-enabled. Try after TIMESTAMP.';
