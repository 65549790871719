import { Injectable } from '@angular/core';
import { UserProjectRoleEnum } from '@core/enums';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { User } from '@theme/interfaces';
import { UserService } from '@theme/services';
import { Observable, tap } from 'rxjs';
import { FeatureFlagsState } from '../feature-flags';
import { UserInfoState } from '../user-info/user-info.state';
import UserStateModel from './user-state-model';
import {
  LoadAllPlatformAdminUsersData,
  LoadAllUserdata,
  LoadUserdata,
  ResetAllUserdata,
  ResetUserdata,
} from './user.actions';

const stateDefaults: UserStateModel = {
  allUserData: null,
  allPlatformAdminUserData: null,
  projectId: '',
  projectName: '',
  projectNickname: '',
  projectNumber: '',
  ec2Status: '',
  ec2Provisioned: false,
  instanceId: '',
  isHeavyLiftingFlagAvailable: '',
  isPowerUser: false,
  isStataFeatureFlagAvailable: '',
  isVscodeFeatureFlagAvailable: '',
  dwFeatureFlag: '',
  serverUrls: { jupyter: '', rstudio: '' },
  memory: '',
  machineSize: '',
  vCPU: '',
  createdBy: '',
  userProjectId: '',
  projectStagingS3Space: '',
  projectStandardS3Space: '',
  projectRestrictedS3Space: [],
  projectRestrictedDataUseGroups: [],
  publicUseS3Space: '',
  publicLimitedS3Space: '',
  userId: '',
  emailId: '',
  userStatus: '',
  modifiedBy: '',
  rVersion: '',
  roleName: UserProjectRoleEnum.BLANK,
  workspaceStatus: '',
  amazonWorkspaceAccess: false,
  bedrockAccess: false,
  lastLoginTime: '',
  pbmqAccess: false,
  platformLevelUserStatus: '',
  projectLevelUserStatus: '',
  projectOffboardedDate: '',
  projectOnboardedDate: '',
  rdsAccess: false,
  snowflakeAccess: false,
  stagingAccess: false,
  disabledDate: '',
  disabledReason: '',
};
@State<UserStateModel>({
  defaults: stateDefaults,
  name: 'Userdata',
})
@Injectable()
export class UserState {
  constructor(private userService: UserService, private store: Store) {}

  @Action(LoadAllPlatformAdminUsersData)
  public LoadAllPlatformAdminUsersData(
    ctx: StateContext<UserStateModel>
  ): Observable<UserStateModel> {
    const reqHeaders = {
      roleName: this.store.selectSnapshot(UserInfoState.getCurrentUserRole),
    };
    return this.userService.getAllPlatformAdminUsers(reqHeaders).pipe(
      tap((users) => {
        const platformAdminUserCount = users.userCount;
        const platformAdminUserInfo = users.userInfo;
        ctx.patchState({ platformAdminUserCount, platformAdminUserInfo });
      })
    );
  }

  @Action(LoadAllUserdata)
  public LoadAllUserdata(
    ctx: StateContext<UserStateModel>,
    { getAllFields, pageNo }: LoadAllUserdata
  ): Observable<UserStateModel> {
    const reqHeaders = {
      roleName: this.store.selectSnapshot(UserInfoState.getCurrentUserRole),
    };
    return this.userService.getAllUsers(getAllFields, reqHeaders, pageNo).pipe(
      tap((users) => {
        if (getAllFields) {
          const allUserData = users.userInfo;
          ctx.patchState({ allUserData });
        } else {
          const userCount = users.userCount;
          //For now using any: TBD
          const stateUserData: any = ctx.getState().userInfo;
          const userInfo =
            ctx.getState().userInfo === undefined || pageNo === 1 || !pageNo
              ? users.userInfo
              : stateUserData?.concat(users.userInfo);

          ctx.patchState({ userCount, userInfo });
        }
      })
    );
  }

  @Action(LoadUserdata)
  public LoadUserdata(
    { patchState }: StateContext<UserStateModel>,
    { requestHeaders }: LoadUserdata
  ): Observable<User> {
    return this.userService.getUser(requestHeaders).pipe(
      tap((users) => {
        const featureFlags = this.store.selectSnapshot(
          FeatureFlagsState.returnFeatureFlags
        );
        users.dwFeatureFlag = featureFlags.dwFeatureFlag;
        users.isHeavyLiftingFlagAvailable =
          featureFlags.isHeavyLiftingFlagAvailable;
        users.isStataFeatureFlagAvailable =
          featureFlags.isStataFeatureFlagAvailable;
        users.isVscodeFeatureFlagAvailable =
          featureFlags.isVscodeFeatureFlagAvailable;
        patchState({ ...users });
      })
    );
  }

  @Selector()
  public static getAllPlatformAdminUsers(
    state: UserStateModel
  ): Partial<UserStateModel> {
    return {
      platformAdminUserCount: state.platformAdminUserCount,
      platformAdminUserInfo: state.platformAdminUserInfo,
    };
  }

  @Selector()
  public static getAllUserData(state: UserStateModel): Partial<UserStateModel> {
    return { allUserData: state.allUserData };
  }

  @Selector()
  public static getAllUsers(state: UserStateModel): Partial<UserStateModel> {
    return { userCount: state.userCount, userInfo: state.userInfo };
  }

  @Selector()
  public static getDWFeatureFlag(state: UserStateModel): string {
    return state.dwFeatureFlag;
  }

  @Selector()
  public static getUsers(state: UserStateModel): User {
    return state;
  }

  @Action(ResetAllUserdata)
  public resetAllUserdata({ patchState }: StateContext<UserStateModel>): void {
    const allUserData = null;
    patchState({ allUserData });
  }

  @Action(ResetUserdata)
  public resetUserdata({ patchState }: StateContext<UserStateModel>): void {
    patchState({ ...stateDefaults });
  }
}
