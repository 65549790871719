<div>
  <div
    *ngIf="
      selectedUserProjects.length === 0 || showSpinner;
      else showManageUserForm
    "
  >
    <div class="mpr-noresult-container">
      <div class="mpr-noresutl-error">
        <span>
          <mat-spinner diameter="45" color="accent"></mat-spinner>
        </span>
      </div>
    </div>
  </div>
  <ng-template #showManageUserForm>
    <div
      class="mpr-manage-users"
      *ngIf="selectedUserDetails && selectedUserDetails.length > 0"
    >
      <div class="mpr-manage-users-header">
        <span class="mpr-selected-User"
          >List of Projects For {{ selectedUserDetails[0].userName }}</span
        >
        <span>
          <mat-spinner
            diameter="20"
            color="accent"
            *ngIf="(fetchUserDetailsInprogress$ | async) || showDelaySpinner"
          ></mat-spinner>
          <ng-container
            *ngIf="
              (fetchUserDetailsInprogress$ | async) === false &&
              !showDelaySpinner
            "
          >
            <button
              [class.mpr-disabled]="
                disallowUserEnableAction(selectedUserDetails[0].disabledDate)
              "
              class="mpr-btn-transparency mpr-cursor-pointer mpr-link"
              [ngClass]="
                selectedUserDetails[0].userStatus !== 'disabled'
                  ? 'disable-button'
                  : 'enable-button'
              "
              (click)="enableDisableUser()"
            >
              {{
                selectedUserDetails[0].userStatus !== 'disabled'
                  ? 'Disable User'
                  : 'Enable User'
              }}
            </button>
            <button
              *ngIf="
                disallowUserEnableAction(selectedUserDetails[0].disabledDate)
              "
              class="mpr-btn-transparency"
              [mtxTooltip]="enableDisabledTooltip"
              [mprMtxTooltipAriaDescribedBy]="enableDisabledTooltip"
              #tooltip="mtxTooltip"
              aria-label="Disable reason"
            >
              <mat-icon class="mpr-info-icon p-t-1">info </mat-icon>
            </button>
          </ng-container>
        </span>
      </div>
      <div class="mpr-tabs-container">
        <table
          *ngIf="selectedUserProjects.length > 0"
          mat-table
          [dataSource]="dataSource"
          class="mat-elevation-z8 staging-upload-history-table mpr-tables one-time-history-table"
        >
          <!-- Project Name Column -->
          <ng-container matColumnDef="projectName">
            <th mat-header-cell *matHeaderCellDef>Project Name</th>
            <td mat-cell *matCellDef="let element">
              {{ element.projectId }}
            </td>
          </ng-container>

          <!-- Role Column -->
          <ng-container matColumnDef="roleName">
            <th mat-header-cell *matHeaderCellDef>Role</th>
            <td mat-cell *matCellDef="let element">
              {{ element.roleName | replaceRoleName }}
            </td>
          </ng-container>

          <!-- Edit Column -->
          <ng-container matColumnDef="edit">
            <th mat-header-cell *matHeaderCellDef>Edit</th>
            <td mat-cell *matCellDef="let element">
              <button
                class="mpr-btn-transparency mpr-cursor-pointer"
                [class.mpr-disabled]="
                  isDisabled(selectedUserDetails[0].userStatus)
                "
                (click)="editUser(selectedUserDetails[0], element)"
              >
                <img
                  src="assets/images/Edit.svg"
                  alt="Edit User"
                  class="img-margin"
                  matTooltip="Edit User"
                />
              </button>
            </td>
          </ng-container>

          <!-- Delete Column -->
          <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef>Delete</th>
            <td mat-cell *matCellDef="let element">
              <button
                [class]="
                  deleteForProject === element.projectId &&
                  (fetchProjectMetadataInProgress$ | async)
                    ? 'mpr-disabled'
                    : ''
                "
                class="mpr-btn-transparency mpr-cursor-pointer"
                (click)="
                  showDeleteConfirmation(selectedUserDetails[0], element)
                "
              >
                <img
                  src="assets/images/delete.svg"
                  alt="Delete User"
                  class="img-margin"
                  matTooltip="Delete User"
                />
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </div>
  </ng-template>
</div>

<ng-template #enableDisabledTooltip>
  <div class="mpr-popover-width">
    {{ getEnableDisabledReasonTooltip() }}
  </div>
</ng-template>
