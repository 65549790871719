import { CommonResponseModel } from '@shared';

export interface RDSClusters {
  clusterName: string;
  dateClusterSetup: string;
  dbEngine: string;
  dbInstanceCount: string;
  dbUserName: string;
  displayStatus: string;
  endpoint: string;
  password: string;
  port: string;
  projectId: string;
  proxyEndpoints: Record<string, ProxyEndpoints>;
  proxyServiceAccountUser: string;
  rdsResourceId: string;
  status: string;
  username: string;
}

export interface RDSResponse {
  data: RDSClusters[] | [];
  dbEngine: string;
  defaultCapacity: string;
  message: string;
  status_code: number;
}

export interface ManageRDSClustersModel {
  commonResponse: CommonResponseModel;
  dbEngine: string;
  defaultCapacity: string;
  generateProxyTokenResponse: GenerateTokenResponseModel;
  generateTokenResponse: GenerateTokenResponseModel;
  loader: boolean;
  rdsClusters: RDSClusters[] | [];
}

export interface GenerateTokenResponseModel extends CommonResponseModel {
  accessToken: string;
}

export interface SetupOrEditClusterPayload {
  action?: string;
  clusterName: string;
  projectId: string;
  rdsResourceId?: string;
}

export interface ProxyEndpoints {
  [key: string]: string;
  status: string;
}
