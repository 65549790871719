<div fxLayout="column" class="mpr-mat-dialog-small">
  <div class="d-flex mpr-create-proxy-heading-wrapper">
    <h1 mat-dialog-title>Create Proxy Endpoint</h1>
    <button
      class="mpr-dialog-close"
      (click)="onCancel()"
      *ngIf="showNoAWSAccountsError"
    >
      <img src="assets/images/cancel-popup.svg" alt="Close icon" />
    </button>
  </div>
  <ng-container *ngIf="showNoAWSAccountsError">
    <div class="d-flex mpr-now-aws-accounts-warning">
      <img
        class="mpr-warning-icon"
        src="assets/images/warning-icon-red-triangle.svg"
      /><span
        >Contact
        <a class="mpr-service-now-link" href="{{ contactUs }}" target="_blank"
          >{{ 'appName' | appDetails }} Support</a
        >
        to configure your project's AWS accounts with
        {{ 'appName' | appDetails }} before you can create a proxy
        endpoint.</span
      >
    </div>
  </ng-container>
  <ng-container *ngIf="!showNoAWSAccountsError">
    <div class="note-content-box">
      <b>
        Enable cross account access to this RDS cluster from your project AWS
        account. Refer to the
        <a
          class="mpr-cross-account-user-guide"
          href="{{
            'relational-database-service-guide/managing-rds-cluster-operations?id=creating-rds-proxy-endpoint'
              | helpLinkUrl
          }}"
          target="_blank"
        >
          <span>user guide</span>
          &nbsp;<img
            class="mpr-new-window-icon"
            src="assets/images/new-window.svg "
            alt=""
          />
        </a>
        &nbsp;for detailed instructions.
      </b>
    </div>
    <div mat-dialog-content class="content-of-popup">
      <form [formGroup]="createProxyEndpointForm" (ngSubmit)="createEndpoint()">
        <div class="form-group">
          <div class="mpr-form-block-field m-t-3">
            <label
              for="clusterName"
              class="mpr-form-block-field-label text-uppercase"
              >Cluster Name</label
            >
            <input
              id="clusterName"
              aria-label="Project Name"
              class="mpr-metadata-form-block-field-input"
              [disabled]="true"
              formControlName="clusterName"
            />
          </div>
        </div>
        <div class="form-group">
          <div class="mpr-form-block-field m-t-3">
            <div
              for="Select AWS Account"
              class="mpr-form-block-field-label text-uppercase"
            >
              Select AWS Account
              <button
                class="mpr-btn-transparency m-t-0"
                [mtxTooltip]="selectAWSInfo"
                [mprMtxTooltipAriaDescribedBy]="selectAWSInfo"
                #tooltip="mtxTooltip"
                aria-label="selectAWSInfo"
              >
                <mat-icon
                  role="img"
                  class="mat-icon notranslate mpr-info-icon mpr-info-big-icon material-icons mat-icon-no-color mpr-icon-info"
                  aria-hidden="true"
                  >info
                </mat-icon>
              </button>
              <ng-template #selectAWSInfo>
                <div class="mpr-popover-width" id="mpr-file-note">
                  Project AWS account you want to connect to this RDS cluster
                </div>
              </ng-template>
            </div>
            <mat-form-field
              class="mpr-mat-select-field mpr-width-percentage-100 mpr-select-aws"
              appearance="outline"
            >
              <mat-select
                formControlName="attributeId"
                id="selectAwsAccount"
                aria-label="Select AWS Account"
              >
                <!-- Add options here -->
                <mat-option
                  *ngFor="let account of enabledCrossAccounts"
                  [value]="account.attributeId"
                >
                  {{ account.accountId }} - {{ account.description }}
                </mat-option>
              </mat-select>

              <mat-error
                *ngIf="
                  createProxyEndpointForm
                    .get('attributeId')
                    ?.hasError('required') &&
                  createProxyEndpointForm.get('attributeId')?.touched
                "
              >
                Please select an AWS Account.
              </mat-error>
              <mat-error
                *ngIf="
                  createProxyEndpointForm.get('attributeId')?.touched &&
                  createProxyEndpointForm
                    .get('attributeId')
                    ?.hasError('proxyExistsForCluster')
                "
              >
                RDS proxy endpoint is already created for the selected AWS
                account.
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div formArrayName="subnetIds" class="mpr-subnetgroup-array">
          <div *ngFor="let control of subnetIds.controls; let i = index">
            <div class="mpr-form-block-field m-t-3">
              <div class="mpr-form-block-field-label text-uppercase">
                Subnet {{ i + 1 }}
                <button
                  class="mpr-btn-transparency m-t-0"
                  [mtxTooltip]="subnetInfo"
                  [mprMtxTooltipAriaDescribedBy]="subnetInfo"
                  #tooltip="mtxTooltip"
                  attr.aria-label="subnetInfo{{ i }}"
                >
                  <mat-icon
                    role="img"
                    class="mat-icon notranslate mpr-info-icon mpr-info-big-icon material-icons mat-icon-no-color mpr-icon-info"
                    aria-hidden="true"
                    >info
                  </mat-icon>
                </button>
              </div>
              <ng-template #subnetInfo>
                <div class="mpr-popover-width" id="mpr-file-note">
                  Shared subnet ID #{{ i + 1 }} from project AWS account
                </div>
              </ng-template>
              <input
                [formControlName]="i"
                placeholder=""
                id="subnetInput{{ i + 1 }}"
                aria-label="Subnet Input"
                class="mpr-metadata-form-block-field-input"
              />
              <mat-error
                class="mpr-mat-error"
                *ngIf="control.hasError('required') && control.touched"
              >
                Please enter Subnet {{ i + 1 }}
              </mat-error>
              <mat-error
                class="mpr-mat-error"
                *ngIf="control.hasError('duplicateSubnetId')"
              >
                Subnet IDs must be unique
              </mat-error>
            </div>
          </div>
        </div>

        <div class="mpr-progressbar-actions" mat-dialog-actions>
          <div *ngIf="disableConfirmShowLoader">
            <mat-spinner diameter="30" color="accent"></mat-spinner>
          </div>
          <div>
            <button
              mat-stroked-button
              mat-dialog-close
              type="button"
              class="mpr-button mpr-blue-button mpr-cancel-button"
            >
              Cancel
            </button>
            <button
              type="submit"
              mat-button
              class="mpr-save-button mpr-button mpr-yellow-button mpr-button-authorize"
              [disabled]="
                createProxyEndpointForm.invalid || disableConfirmShowLoader
              "
            >
              Create Endpoint
            </button>
          </div>
        </div>
      </form>
    </div>
  </ng-container>
</div>
